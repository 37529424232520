import { template as template_51fb5d1876034eef96b553a053b2d541 } from "@ember/template-compiler";
const FKText = template_51fb5d1876034eef96b553a053b2d541(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
