import { template as template_5014145e2bc54f3ab853a2365f54006e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const SidebarSectionHeader = template_5014145e2bc54f3ab853a2365f54006e(`
  {{#if @collapsable}}
    <DButton
      @title="sidebar.toggle_section"
      @action={{@toggleSectionDisplay}}
      @forwardEvent={{true}}
      aria-controls={{@sidebarSectionContentId}}
      aria-expanded={{if @isExpanded "true" "false"}}
      class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
    >
      {{yield}}
    </DButton>
  {{else}}
    <span class="sidebar-section-header">
      {{yield}}
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionHeader;
