import { template as template_34b2aaf0a0aa495880bd8654b9b760ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_34b2aaf0a0aa495880bd8654b9b760ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
