import { template as template_909b6a417bb74b0aa6cf46694cea37c4 } from "@ember/template-compiler";
const WelcomeHeader = template_909b6a417bb74b0aa6cf46694cea37c4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
