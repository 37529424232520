import { template as template_415dc4bb8e574fe59d760a8dad11987d } from "@ember/template-compiler";
const FKLabel = template_415dc4bb8e574fe59d760a8dad11987d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
